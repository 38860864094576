import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { SoloCharacter } from '../../../modules/solo/common/components/solo-character';
import { Row, Col } from 'react-bootstrap';
import { SoloWeapon } from '../../../modules/solo/common/components/solo-weapon';

const SoloGuidesDupes: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dupe Priorities</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_dupe.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Dupe Priorities</h1>
          <h2>
            Dupe priorities for characters and weapons in Solo Leveling: Arise.
          </h2>
          <p>
            Last updated: <strong>20/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Hunters" />
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="meilin-fisher"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          A1 is Meilin's biggest Power Spike, providing a significant increase
          in core damage buff. A2 also provides a nice ATK/DEF buff but is more
          situational. Her A5 makes her a monster all around support doubling
          her buffs offered by "Pumped up" from 8% to 16%. However, the increase
          is mostly overkill at this stage of the game.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="alicia"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          At A0 she is sitting at S+ Tier, while at A5 she sits in the same tier
          of Cha at SS. Her dupes are simply one of the strongest in the game,
          turning her into a monster.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="cha-hae-in"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          A1 is a very accessible sweet spot that gives a nice powerboost with
          extra stacks for 'The Dancer'. A3 is probably her biggest damage
          spike, increasing the skill damage of The Dancer and Sword of Light by
          50%. Obviously A5 is even better but you can potentially pick it up
          when she is added to the standard banner.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="seo-jiwoo"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          Every dupe slowly turns Seo in a killing machine, adding more skill
          damage, break damage, mp recovery and critical hit rate/damage.
          Absolutely one of the hunters that benefits the most from going all
          the way to A5.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="woo-jinchul"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          More like "Why Jinchul, Why?". His dupes are ok but nothing you should
          actively chase.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="min-byung-gu"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          Absolutely A1, there's nothing better than a free critical hit/damage
          buff to the whole party. Couple this with his his personal weapon and
          Min becomes an invaluable aura bot for the team even when you don't
          need his healing.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="choi-jong-in"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          A1 makes him MUCH stronger with the extra damage and skill change.
          Besides that, every dupe makes him more powerful, with A5 turning him
          in a monster when tagging out.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="baek-yoonho"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          If you are using him as a support breaker, A0 is ok. Every dupe give
          him more damage or breaking potential. A5 is a massive powerspike
          since he can ult as soon as he enters the stage, setting up a powerful
          defense break before tagging Cha in. A bit unnecessary considering she
          alone deals more than enough damage, but a welcome addition
          regardless.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="emma-laurent"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          A1 is ok since it boosts her break damage a lot. A3 is interesting
          because it shares her defensive power with the rest of the team, so it
          could be great down the line in fights with a lot of unavoidable
          damage.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="baek-yonhoo-silver-mane"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          A1 is almost mandatory to get him rolling, as it massively increases
          his critical hit chance/damage and dr. His later dupes are actually
          great, especially A3 (40% more basic and core attack damage to
          bleeding chars). A4 is also nice for more basic attack. Absolutely a
          char you won't regret putting in wishlist when he's added to regular
          banner
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="lee-bora"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          as a support is fine at A0. Subsequent dupes mostly increase her
          personal damage, A1 giving her an extra fox with [Phantom Foxes] and
          A3 giving her an extra cast of Strengthening Charm and more skill
          range. With the latest changes she become an actual powerful dps if
          you want to invest into her.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="lim-tae-gyu"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          A1 turns Lim from a good character to a GREAT character, giving him
          much needed skill speed and damage for Shoot and Maneuver, on top of a
          third usage of the skill.
        </p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="hwang-dongsoo"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>
          A3 is probably the most notable dupe for the madness buff, but A1 is
          already a good improvement for the level 2 charge. A4 and A5 increase
          his ultimate damage MASSIVELY.
        </p>
        <SectionHeader title="Weapons" />
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon
              name="Demonic Plum Flower Sword"
              mode="card"
              showLabel
            />
          </Col>
        </Row>
        <p>
          A1 adds a lot of power with 32% crit rate and skill damage. Never take
          this off until A5 - the power it gets with every dupe is insane.
        </p>
        <p>
          It's important to notice that Plum has a more peculiar playstyle
          revolving around planning dashes and sheathing the weapon, making it a
          bit more 'technical'. Once you get your Plum to A1 please play around
          with it a bit and decide to push it to A5 only if you find yourself
          enjoying the weapon. Otherwise you can go with a more simple choice
          like Scythe.
        </p>
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon name="Shadow Scythe" mode="card" showLabel />
          </Col>
        </Row>
        <p>
          A1 gives the critical hit buff and that's already most of the weapon's
          value. Dupes are powerful but probably not a priority to wishlist.
        </p>
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon name="Thethis' Grimoire" mode="card" showLabel />
          </Col>
        </Row>
        <p>
          A1 is the most immediate powerspike for the weakness damage taken
          debuff, provided you are bringing the correct weapon for the fight. A5
          is a massive 50% extra damage to frozen targets, if you happen to get
          it, but it's not a priority with how limited the wishlist is.
        </p>
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon name="Moonshadow" mode="card" showLabel />
          </Col>
        </Row>
        <p>
          A1 unlocks the true potential of the weapon with the extra stacks of
          Full Moon, making it almost a different weapon. A3 is also extremely
          powerful for more uptime on the lunar eclipse. Honestly, once you add
          it to the wishlist don't remove it, but can stop it at A1 to finish up
          other priorities before finishing hunting for dupes.
        </p>
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon name="Vulcan's Rage" mode="card" showLabel />
          </Col>
        </Row>
        <p>
          A1 and A3 are very powerful power spikes after the buff, but A1 is
          enough.
        </p>
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon name="Demon King’s Longsword" mode="card" showLabel />
          </Col>
        </Row>
        <p>
          The weapon requires you to counter to unleash most of his power and A3
          fixes that by making it activate regardless. A1 is also a nice early
          powerspike. Might not look powerful at first glance but seriously
          consider getting it to build it if you start getting some dupes as
          offbanners or from the special draws. The recent buff to casting speed
          make A3 much stronger.
        </p>
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon name="The Huntsman" mode="card" showLabel />
          </Col>
        </Row>
        <p>
          A1 adds the Sunder/Destroy debuffs that turns the weapon from great to
          amazing when facing bosses that can be broken. A5 makes the weapon
          hilariously powerful, making destroy stack up to 99 times.
        </p>
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon name="Skadi" mode="card" showLabel />
          </Col>
        </Row>
        <p>
          A1 grants the very powerful domain bonus. A3 allows you to stack the
          buff you receive multiple times, like Moonlight shadows, making it a
          much more powerful weapon. Scales very well with dupes.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesDupes;

export const Head: React.FC = () => (
  <Seo
    title="Dupe Priorities | Solo Leveling: Arise | Prydwen Institute"
    description="Dupe priorities for characters and weapons in Solo Leveling: Arise."
    game="solo"
  />
);
